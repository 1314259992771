import { environmentVariables } from "../variables/environmentVariables";

export const QUERY_PATH = {
  GET_EVENT_LIST: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events`,
  GET_EVENT_PDF_LIST: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/export/pdf`,
  GET_EVENT_CSV_LIST: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/export/csv`,
  GET_EVENT_XLS_LIST: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/export/xls`,
  GET_USERS_LIB: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/users`,
  GET_OBJECTS_LIB: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/objects`,
  GET_CATEGORIES_LIB: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/categories`,
  GET_OIKS_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/names`,
  GET_OIKS_SHORT_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/short`,
  SAVE_OIK_HANDOVER_PERIOD: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/transfer-history/case_transfer_dates`,
  GET_OIK_YEARS_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/case_transfer_years`,
  GET_EVENT_TYPE_LIB: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit-event-type`,
  GET_AUDIT_OBJECT_TYPE: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit-object-type`,
  GET_OIKS_AUTOCOMPLETE_ITEMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/search`,
  GET_INVENTORIES_AUTOCOMPLETE_ITEMS: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/search`,
  GET_FUND_TREE_ITEMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/hierarchy`,
  GET_FUND_AUTOCOMPLETE_ITEMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/search`,
  GET_FUND_AUTOCOMPLETE_NUMBER_ITEMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/search-by-number`,
  GET_FUND_AUTOCOMPLETE_ITEMS_BY_ID: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/by-id`,
  GET_ARCHIVE_AUTOCOMPLETE_ITEMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive/search`,
  GET_OIKS_AUTOCOMPLETE_ITEMS_BY_ID: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/by-id`,
  GET_INVENTORIES_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/`,
  GET_INVENTORIES_KINDS: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/kinds`,
  GET_INVENTORIES_TYPES: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/types`,
  GET_INVENTORIES_CATEGORIES: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/categories`,
  GET_INVENTORIES_TMP_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/find`,
  GET_CASE_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/`,
  GET_INVENTORIES_PDF_CARD: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/export`,
  GET_INVENTORIES_PDF_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/export/pdf`,
  GET_INVENTORIES_PDF_LIST_ASYNC: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/export_async/pdf`,
  GET_INVENTORIES_CSV_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/export/csv`,
  GET_INVENTORIES_XLS_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/export/xls`,
  GET_INVENTORY_YEARS_LIB: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/years`,
  GET_INVENTORY_KINDS_LIB: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/kinds`,
  GET_CASE_YEAR_STAT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/year_stat`,
  GET_EAD_MONTH_STAT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/month-stat`,
  GET_EAD_YEAR_STAT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/year_stat`,
  GET_EAD_SEARCH_AUTOCOMPLETE: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/suggest`,
  GET_ERC_SEARCH_AUTOCOMPLETE: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/suggest`,
  GET_ACT_SEARCH_AUTOCOMPLETE: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/suggest`,
  GET_DOC_TYPE_SEARCH_AUTOCOMPLETE: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/kind/suggest`,
  GET_INVENTORY_STATUSES_LIB: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/statuses`,
  SAVE_INVENTORY_COMMENT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/comment`,
  SAVE_INVENTORY_HISTORICAL_NOTE: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/historicalNote`,
  SAVE_CASE_COMMENT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/comment`,
  SAVE_PAPER_STATUS: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/status`,
  FORM_INVNTORY_ACT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act`,
  GET_EAD_STATUSES_LIB: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/statuses`,
  GET_EAD_TREE_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/hierarchy`,
  GET_SOURCES_LIB: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/events/sources`,
  GET_TK_ELEMENT_CARD: `${environmentVariables.WORKER_SERVICE_PATH}/api/container`,
  GET_TK_ELEMENTS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/logs`,
  GET_TK_LOG_CSV_LIST: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/log/export/csv`,
  GET_TK_LOG_PDF_LIST: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/log/export/pdf`,
  GET_TK_LOG_XLS_LIST: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/log/export/xls`,
  GET_TK_STATUSES: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/statuses`,
  GET_TK_TYPES: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/types`,
  GET_TK_INCOMING_TYPES: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/income_types`,
  GET_TK_STEPS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/steps-count`,
  GET_TK_LIST_ELEMENTS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container`,
  GET_TK_LIST_CSV: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/export/csv`,
  GET_TK_LIST_PDF: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/export/pdf`,
  GET_TK_LIST_PDF_ASYNC: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/export_async/pdf`,
  GET_TK_LIST_XLS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/export/xls`,
  GET_TK_RESPONSE_OIK: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/download`,
  GET_EAD_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead`,
  GET_EAD_TMP_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/find`,
  GET_EAD_CARD_PDF: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/export`,
  SAVE_EAD_COMMENT: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/comment`,
  ADD_TO_ELASTIC: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/ead-file/upload-to-elastic`,
  RELOAD_PREVIEW_EAD: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/reload-preview`,
  SEARCH_IN_CARD: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/find`,
  GET_CATALOG_FUND_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund`,
  GET_CATALOG_FUND_CSV: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/export/csv`,
  GET_CATALOG_FUND_PDF: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/export/pdf`,
  GET_CATALOG_FUND_XLS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/export/xls`,
  CONCATED_FUND: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/merge`,
  GET_CATALOG_ARCHIVE_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive`,
  GET_CATALOG_ARCHIVE_ALL: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive/all`,
  GET_CATALOG_ARCHIVE_PDF: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive/export/pdf`,
  GET_CATALOG_ARCHIVE_CSV: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive/export/csv`,
  GET_CATALOG_ARCHIVE_XLS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive/export/xls`,
  GET_CATALOG_ARCHIVE_HIERARCHY: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/archive/hierarchy`,
  GET_CATALOG_DOC_TYPE_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/kind`,
  GET_STORAGE_TERMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/kind/storage-term-value`,
  GET_CATALOG_VERSIONS_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/version/document/kind`,
  SEND_VERSION_TO_NSI: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/create/construct-gas-nsi-container`,
  GET_VERSIONS_AUTOCOMPLETE: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/version/document/kind/sources`,
  TRANSFER_DOCUMENT: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/kind/move`,
  TRANSFER_SECTION: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/section/move`,
  DOC_TYPE_SECTION: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/section`,
  DOC_TYPE_ADD_DOCUMENT: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/kind/add`,
  GET_CATALOG_DOC_TYPE_HIERARCHY: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/section/all`,
  // GET_CATALOG_DOC_TYPE_CHAPTERS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/section/subsection/all`,
  GET_CATALOG_DOC_TYPE_SECTIONS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/document/section/all`,
  GET_CATALOG_OIK_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik`,
  GET_OIK_NOTIFY: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/transfer-history/notify`,
  SAVE_CATALOG_OIK_CATALOG_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/contract`,
  GET_CATALOG_OIK_PDF: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/export/pdf`,
  GET_CATALOG_OIK_CSV: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/export/csv`,
  GET_CATALOG_OIK_XLS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/export/xls`,
  GET_HANDOVER_OIK_PDF: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/export_case_transfer/pdf`,
  GET_HANDOVER_OIK_CSV: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/export_case_transfer/csv`,
  GET_CATALOG_OIK_CARD_ELEMENT: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/card`,
  SYNCHRONIZE_OIK: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export`,
  GET_EAD_PDF_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/export/pdf`,
  GET_EAD_PDF_LIST_ASYNC: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/export_async/pdf`,
  GET_EAD_CSV_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/export/csv`,
  GET_EAD_XLS_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/export/xls`,
  GET_AUDIT_CSV_LIST: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit/journal/export/csv`,
  GET_AUDIT_PDF_LIST: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit/journal/export/pdf`,
  GET_AUDIT_PDF_LIST_ASYNC: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit/journal/export_async/pdf`,
  GET_AUDIT_XLS_LIST: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit/journal/export/xls`,
  GET_SERVER_VERSION: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/applications`,
  AUTHENTICATION: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/api/user`,
  GET_USER_TABLE_RIGHT_SETTINGS: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/api/settings`,
  GET_SECTION_SETTINGS: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/api/settings/section`,
  GET_REPORT_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/documents/preview`,
  GET_REPORT_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/documents/export_async/pdf`,
  GET_REPORT_CSV: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/documents/export/csv`,
  GET_REPORT_XLS: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/documents/export/xls`,
  GET_INVENTORY_REGISTER_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/inventory-ead/preview`,
  GET_INVENTORY_REGISTER_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/inventory-ead/export_async/pdf`,
  GET_INVENTORY_REGISTER_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/inventory-ead/export_async/docx`,
  GET_FUND_REPORT_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds/preview`,
  GET_FUND_REPORT_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds/export_async/pdf`,
  GET_FUND_REPORT_CSV: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds/export/csv`,
  GET_FUND_REPORT_XLS: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds/export/xls`,
  GET_FUND_NAME_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/names`,
  GET_FUND_SHORT_ITEM_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/short/all`,
  GET_FILE_FROM_FILE_STORAGE: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/download`,
  UPLOAD_FILE_TO_FILE_STORAGE: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/upload`,
  GET_FILE_META: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/metadata`,
  GET_TXT_FILE_FROM_FILE_STORAGE: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/download-part`,
  GET_NOTIFICATION_LIST: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/notifications`,
  GET_NOTIFICATION_ITEM: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/notifications/card`,
  GET_NOTIFICATION_TRIGGERS: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/notifications/triggers`,
  GET_NOTIFICATION_TRANSPORTS: `${environmentVariables.NOTIFICATION_SERVICE_PATH}/api/notifications/transports`,
  GET_HDUO_DOWNLOAD: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/file/download`,
  GET_RECOGNIZED_CONTENT: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/export_easydoc`,
  GET_RECEIPT_DOWNLOAD: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/download`,
  GET_AK_STATUSES_LIB: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/statuses`,
  GET_AK_OIKS_LIB: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ak/oiks`,
  GET_AK_TYPES_LIB: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/types`,
  GET_AK_EXPORT: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/export`,
  GET_AK_PDF_LIST: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/export_async/pdf`,
  GET_AK_CSV_LIST: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/export/csv`,
  GET_AK_XLS_LIST: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container/export/xls`,
  GET_AK_LIST: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/archive-container`,
  GET_MRP_LIST: `${environmentVariables.VUE_APP_CRYPTO_SERVICE_PATH}/api/mrd`,
  GET_MRP_PERMISSIONS: `${environmentVariables.VUE_APP_CRYPTO_SERVICE_PATH}/api/mrd/permissions`,
  GET_RENEWAL_AK_RECEIPT: `${environmentVariables.VUE_APP_HDUO_SERVICE_PATH}/api/receipt/legal-renew`,
  GET_SERVICE_DICTIONARY_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/dictionaries`,
  GET_GENERATED_REPORT: `${environmentVariables.REPORT_SERVICE_PATH}/api/generated-report`,
  GET_SCHEDULED_REPORT: `${environmentVariables.REPORT_SERVICE_PATH}/api/scheduled-report`,
  GET_END_EAD_REPORT_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/ending-ead-storage/preview`,
  GET_END_EAD_REPORT_EXPORT: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/ending-ead-storage/export`,
  RETRY_CONTAINER: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/retry`,

  GET_DIGITAL_SIGNATURES: `${environmentVariables.VUE_APP_CRYPTO_SERVICE_PATH}/api/signature`,

  AUTHENTICATION_SEND_EMAIL_RESET_PASSWORD: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/users/reset-password`,
  AUTHENTICATION_CHECK_OLD_PASSWORD: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/users/check-old-password`,
  AUTHENTICATION_SAVE_NEW_PASSWORD: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/users/password`,
  AUTHENTICATION_LOGIN: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/sign-in`,
  AUTHENTICATION_LOGIN_SECOND_STEP: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/check_otp`,
  AUTHENTICATION_RECREATE_OTP: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/recreate_otp`,
  AUTHENTICATION_REFRESH_TOKEN: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/refresh_token`,
  AUTHENTICATION_LOGOUT: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/logout`,
  AUTHENTICATION_CHECK_TOKEN: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/token`,
  GET_OTP_EXPIRATION_TIME: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/otp_resend_cooldown`,
  GET_TWO_STEPS_AUTH_SETTING: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/auth/enable2fa`,
  AUTHENTICATION_USER_LIST: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/users`,
  AUTHENTICATION_EXTERNAL_USER_LIST: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/external-system`,
  AUTHENTICATION_ROLE_LIST: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/roles`,
  AUTHENTICATION_ROLE_AUTHORITIES_LIST: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/role-authorities`,
  AUTHENTICATION_ROLE_AUTHORITIES_GROUPS_LIST: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/authority-groups`,
  AUTHENTICATION_USER_ACCESSES: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/api/user-access/access`,
  AUTHENTICATION_EXTERNAL_USER_ACCESSES: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/api/external-system-access/access`,

  GET_COLL_STATE: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/coll-state`,

  GET_ELASTIC_SETTING: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/setting/ELASTIC_ENABLED`,

  GET_BUG_REPORT: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/service-request`,
  GET_BUG_REPORT_CSV: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/service-request/export/csv`,
  GET_BUG_REPORT_PDF: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/service-request/export/pdf`,
  GET_BUG_REPORT_XLS: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/service-request/export/xls`,

  GET_SEARCH_TEMPLATE: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/search-template`,

  GET_BROSING_HISTORY: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/card-browsing-history`,

  GET_CARD_MODES: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/card-view-mode`,

  GET_VIEW_MODES: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/view-modes`,

  GET_AUDIT_LIST: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit`,

  GET_FILE_STORAGE: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/files`,
  GET_FILE_STORAGE_OBJECT_TYPES: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/attributes/object-types`,
  GET_FILE_STORAGE_CSV_LIST: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/files/export/csv`,
  GET_FILE_STORAGE_PDF_LIST: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/files/export/pdf`,
  GET_FILE_STORAGE_XLS_LIST: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/files/export/xls`,

  GET_GRAPH_OIK_DATA: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export`,

  GET_SETTINGS_TEMPLATE: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/setting/section`,
  GET_AUDIT_TYPES_NOTIFY: `${environmentVariables.VUE_APP_AUDIT_SERVICE_PATH}/api/audit-event-type`,
  GET_VIPNET_CERTS: `${environmentVariables.VUE_APP_CRYPTO_SERVICE_PATH}/api/certificates`,
  GET_VIPNET_CERTS_ALL: `${environmentVariables.VUE_APP_CRYPTO_SERVICE_PATH}/api/certificates/all`,
  GET_SETTINGS_DATA: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/setting/short`,
  GET_SETTINGS: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/setting`,
  GET_MONITORING_BUSSINESS_PROCESSES: `${environmentVariables.VUE_APP_ADMINISTRATION_SERVICE_PATH}/api/applications`,

  GET_GRAPH_MONITORING_PDF: `${environmentVariables.WORKER_SERVICE_PATH}/api/monitoring/monitoring-bp/export/pdf`,
  GET_GRAPH_MONITORING_CSV: `${environmentVariables.WORKER_SERVICE_PATH}/api/monitoring/monitoring-bp/export/csv`,
  GET_GRAPH_MONITORING_XLS: `${environmentVariables.WORKER_SERVICE_PATH}/api/monitoring/monitoring-bp/export/xls`,
  GET_GRAPH_MONITORING_LIST: `${environmentVariables.WORKER_SERVICE_PATH}/api/monitoring/monitoring-bp/getProcessTime`,

  GET_AVAILABLE_USER_SETTINGS: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/user-settings`,
  GET_AVAILABLE_USER_SETTINGS_VALUES: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/user-settings/values`,

  GET_OIK_SYNC_LOG: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/oik-logs`,
  GET_OIK_SYNC_LOG_PDF: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/oik-logs/export/pdf`,
  GET_OIK_SYNC_LOG_CSV: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/oik-logs/export/csv`,
  GET_OIK_SYNC_LOG_XLS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/oik-logs/export/xls`,
  GET_ALL_SYNC_LOG: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/all-logs`,
  GET_ALL_SYNC_LOG_PDF: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/all-logs/export/pdf`,
  GET_ALL_SYNC_LOG_CSV: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/all-logs/export/csv`,
  GET_ALL_SYNC_LOG_XLS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/all-logs/export/xls`,

  GET_OIK_LOG_STATUS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/dictionary-export/statuses`,

  GET_MOCK_GENERATOR_STATUS: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/status`,

  GET_OIKS_SHORT_NAMES_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/short_names`,
  GET_NSI_TK_LIB: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/without_gas_nsi_response`,
  GET_OIKS_SHORT_OBJECT_LIB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/short`,
  GET_FILE_TYPES_LIB: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/file_types`,
  GET_DIC_RESPONSE_CODES: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/dic_response_codes`,
  GET_OIK_RESPONSE: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/without_oik_response`,
  GET_TK_GENERATE: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/create_inventory`,
  GET_TK_COMISSION_GENERATE: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/create_inventory_from_proj`,
  GET_TK_ERC_PROTOCOLS_GENERATE: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/create_inventory_proj`,
  GET_ERC_PROTOCOLS_PDF: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/export/pdf`,
  GET_ERC_PROTOCOLS_PDF_ASYNC: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/export_async/pdf`,
  GET_ERC_PROTOCOLS_CSV: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/export/csv`,
  GET_ERC_PROTOCOLS_XLS: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/export/xls`,
  GET_TK_OIK_GENERATE: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/create_archive_list`,
  CLEAR_GENERATED_TK_FOLDER: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/clean_generated_tk`,
  MOVE_GENERATED_TK: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/container_action`,
  GET_HAS_GENERATED_TK: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/has_tk`,

  DELETE_TK_INFORMATION: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/clean-up`,
  DELETE_ALL_ITEMS: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/clean-up`,
  DELETE_ALL_DICTIONARIES: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/clean-up`,
  DELETE_SELCTED_TK: `${environmentVariables.FILE_STORAGE_SERVICE_PATH}/api/storage/clean-up-tk`,
  ADD_DEFAULT_ITEMS: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/oik/generate-default`,

  GET_EXPERT_COMMISSION_LIST: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory`,
  GET_AUTHORITY_USER_LIST: `${environmentVariables.AUTHENTICATION_SERVICE_PATH}/identity/authority`,
  GET_EXPERT_COMMISSION_PDF_LIST: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory/export/pdf`,
  GET_EXPERT_COMMISSION_PDF_PROTOCOL: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/inventory-project/export/pdf`,
  GET_EXPERT_COMMISSION_PDF_LIST_ASYNC: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory/export_async/pdf`,
  GET_EXPERT_COMMISSION_CSV_LIST: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory/export/csv`,
  GET_EXPERT_COMMISSION_XLS_LIST: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory/export/xls`,

  CREATE_EPC: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/expert-commission-info/create`,
  GET_COMISSION_FILTER_NUMBER_AUTOCOMPLETE_ITEMS: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory/search`,
  COORDINATE_EPC: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/expert-commission-info/coordinate`,
  BLOCK_COMISSION: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/expert-commission-info/approve`,
  FINAL_APPROVE_COMISSION: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/expert-commission-info/final-approve`,
  DELETE_COMISSION_FILE: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/member/file`,
  GET_COMISSION_FILTER_STATUSES: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/expert-commission-info/statuses`,

  GET_ACT_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act`,
  GET_ACT_TMP_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/find`,
  GET_ACT_CSV_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/export/csv`,
  GET_ACT_PDF_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/export/pdf`,
  GET_ACT_PDF_LIST_ASYNC: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/export_async/pdf`,
  GET_ACT_XLS_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/export/xls`,
  GET_ACTS_BY_INVENTORY: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/act/inventory`,

  GET_ARCHIVED_DOCUMENTS_LIST: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archived-documents/preview`,
  GET_ARCHIVED_DOCUMENTS_EXPORT_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archived-documents/export_async/PDF`,
  GET_ARCHIVED_DOCUMENTS_EXPORT_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archived-documents/export_async/DOCX`,

  GET_FUNDS_LIST: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-short/preview`,
  GET_FUNDS_EXPORT_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-short/export_async/PDF`,
  GET_FUNDS_EXPORT_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-short/export_async/DOCX`,

  GET_ARCHIVE_PASSPORT_REPORTS_LIST: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archive-passport/preview`,
  GET_ARCHIVE_PASSPORT_REPORTS_EXPORT_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archive-passport/export_async/PDF`,
  GET_ARCHIVE_PASSPORT_REPORTS_EXPORT_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archive-passport/export_async/DOCX`,

  GET_ARCHIVE_DOCUMENTS_REPORTS_LIST: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/cases-documents/preview`,
  GET_ARCHIVE_DOCUMENTS_REPORTS_EXPORT_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/cases-documents/export_async/PDF`,
  GET_ARCHIVE_DOCUMENTS_REPORTS_EXPORT_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/cases-documents/export_async/DOCX`,

  GET_FUNDS_LIST_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-list/preview`,
  GET_FUNDS_LIST_TOP_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-list/info`,
  GET_FUNDS_LIST_PREVIEW_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-list/export/DOCX`,
  GET_FUND_LIST_PREVIEW_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-list/export_async/PDF`,
  GET_FUND_LIST_PREVIEW_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/funds-list/export_async/DOCX`,

  GET_ARCHIVE_SHORT_PASSPORT_PREVIEW: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archive-short-passport/preview`,
  GET_ARCHIVE_SHORT_PASSPORT_PREVIEW_DOCX: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archive-short-passport/export_async/DOCX`,
  GET_ARCHIVE_SHORT_PASSPORT_PREVIEW_PDF: `${environmentVariables.REPORT_SERVICE_PATH}/api/report/archive-short-passport/export_async/PDF`,

  GET_AUTOMATE_OIK_TRANSFER_END_JOB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/jobs/notify-oik-transfer-end-job`,
  GET_AUTOMATE_TRANSFER_HISTORY_JOB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/jobs/update-transfer-history-job`,
  GET_AUTOMATE_START_OIK_UPDATE_JOB: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/jobs/start-oik-update-job`,
  GET_GENERATE_TK_NSI: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/create_response_gas_nsi`,
  GET_START_INDEXING: `${environmentVariables.VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH}/api/common-elastic/start-indexing`,
  CHECK_TSA_URI: `${environmentVariables.VUE_APP_CRYPTO_SERVICE_PATH}/api/certificates/check-ts`,

  CONTAINER_STEPS: `${environmentVariables.WORKER_SERVICE_PATH}/api/container/steps`,

  GET_NOMENCLATURE_YEARS: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/nomenclature/years`,
  GET_NOMENCLATURE_BY_YEAR: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/nomenclature/by_oik_year`,
  GENERATE_NOMENCLATURE: `${environmentVariables.INTEGRATION_TEST_SERVICE_PATH}/api/create_nomenclature`,

  GET_INVENTORY_PROTOCOLS_BY_ID: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/inventory/by_inventory_id`,

  GET_FUND_TREE_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/fund/hierarchy`,
  GET_FUND_EAD_TREE_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/hierarchy-ead`,
  GET_INV_TREE_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/inventory/hierarchy/by-fund-code`,
  GET_CASE_TREE_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/hierarchy/by-inventory-id`,
  GET_EAD_BY_CASE_TREE_LIST: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/ead/hierarchy/by-case-id`,
  GET_CASE_HIERARCHY: `${environmentVariables.DOCUMENT_REGISTRATION_SERVICE_PATH}/api/case/hierarchy`,
  GET_CASE_ERC_TREE_LIST: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/case/hierarchy/by-inventory-id`,
  GET_CASE_ERC_HIERARCHY: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/case/hierarchy`,
  GET_EAD_FOR_COMMISION_BY_CASE_TREE_LIST: `${environmentVariables.EXPERT_COMISSION_SERVICE_PATH}/api/ecs/ead/hierarchy/by-case-id`,

  GET_STORAGE_CATALOG_LIST: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/address-node-dictionary`,
  GET_STORAGE_TYPES: `${environmentVariables.DICTIONARY_SERVICE_PATH}/api/address-node-dictionary/types`,
};
