import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey as TkGenerationViewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";

const TkGenerationView = defineAsyncComponent(
  () => import(/* webpackChunkName: "TkGenerationView" */ "@monorepo/administration/src/views/TkGeneration/TkGeneration.vue")
);

export const commonRoutes = [
  {
    path: "/administration/tk-generation",
    component: TkGenerationView,
    meta: { requiresAuth: true, key: TkGenerationViewUniqKey, isNeedNavigationPanel: true },
  },
];
